<template>
  <div id="app">
    
    <Topbar />  

    <Navbar />
      
    <SEO />

    <Footer />

    <back-to-top bottom="2rem" right="1.5rem" visibleoffset="800">
        <a class="button-to-top">&#8679;</a>
    </back-to-top>

    <cookie-law theme="amaretis" buttonText="OK">
      <div slot="message">
        Diese Website benutzt Cookies. Wenn du die Website weiter nutzt, gehen wir von deinem Einverständnis aus.
      </div>
    </cookie-law>

  </div>
</template>

<script>
import CookieLaw from 'vue-cookie-law'  
import Navbar from './components/base/Navbar'
import Topbar from './components/base/Topbar'
import SEO from './views/SEO'
import Footer from './components/base/Footer'
import BackToTop from 'vue-backtotop'

export default {
  name: 'app',
  components: {
    CookieLaw,
    Navbar,
    Topbar,
    Footer,
    BackToTop,
    SEO,
  },
  data() {
    return {
      
    }
  },
  created() {
   setTimeout(() => {
    document.dispatchEvent(new Event('render-event'));
   }, 2000);
  },
  methods: {
    
  }
}
</script>


<style lang="scss">
.Cookie {
  
  &__content {
    font-size: 1.7rem;
    font-family: $font-primary;

    a {
      color: $color-white;
      transition: all .3s ease;

      &:hover {
        color: $color-primary;
      }
    }
  }

  &--amaretis {
    background: $color-secondary;
    padding: 1.5rem;
    color: $color-white;
  }

  &__button {
    padding: .5rem 2.5rem;
    background-color: $color-primary;
    transition: all .3s ease;
    font-size: 1.7rem;

    &:hover {
      transform: translateY(-.1rem);
      background-color: $color-white;
      color: $color-primary;
    }
  }
}

.button-to-top {
  padding: .5rem 1.5rem;
  background-color: $color-secondary;
  border-radius: 4px;
  color: $color-white;
  font-size: 2.5rem;
}
</style>
