<template>
	<main>
    <Intro />
    <TextContent />
    <IconBoxes />
    <Banner />
    <Enumeration />
    <Slider />
    <Amaretis />
  </main>
  

</template>

<script>  
import Intro from '../components/base/Intro'  
import TextContent from '../components/seo/TextContent'  
import IconBoxes from '../components/seo/IconBoxes'  
import Banner from '../components/seo/Banner'
import Enumeration from '../components/seo/Enumeration'
import Amaretis from '../components/seo/Amaretis'
import Slider from '../components/seo/Slider'
export default {
  name: 'seo',
  components: {
    Intro,
    TextContent,
    IconBoxes,
    Banner,
    Enumeration,
    Amaretis,
    Slider,
  },
  data() {
    return {
      
    }
  },
  created() {
    
  },
  methods: {
  
  }
}
</script>

<style scoped lang="scss">
main {
  padding-top: 12.3rem;
  @media screen and (max-width: 991px) {
    padding-top: 10.5rem;
  }
}
</style>
