<template>
  <div class="container amaretis" id="contact">
    <h2 class="heading-primary">Warum Sie sich für Amaretis entscheiden sollten?</h2>
    <ul class="amaretis__list" v-observe-visibility="showContent">
      <li class="amaretis__list--item"><i class="fas fa-check amaretis__arrow fa-check amaretis__arrow-1" :class="{'scaleOrg' : content}"></i>Kompetente Ansprechpartner mit langjähriger SEO-Erfahrung</li>
      <li class="amaretis__list--item"><i class="fas fa-check amaretis__arrow fa-check amaretis__arrow-2" :class="{'scaleOrg' : content}"></i>Moderne SEO-Tools und individuelle, auf Sie zugeschnittene SEO-Maßnahmen zur Optimierung Ihrer Website</li>
      <li class="amaretis__list--item"><i class="fas fa-check amaretis__arrow fa-check amaretis__arrow-3" :class="{'scaleOrg' : content}"></i>Abstimmung der Ziele mit dem Kunden und Entwicklung einer konkreten Strategie</li>
      <li class="amaretis__list--item"><i class="fas fa-check amaretis__arrow fa-check amaretis__arrow-4" :class="{'scaleOrg' : content}"></i>Ausführliche SEO- und Tätigkeitsreportings in regelmäßigen Abständen</li>
      <li class="amaretis__list--item"><i class="fas fa-check amaretis__arrow fa-check amaretis__arrow-5" :class="{'scaleOrg' : content}"></i>Persönliche Vorort-Termine im Raum Kassel und Telefonkonferenzen</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Amaretis',
  props: {
    
  },
  data () {
    return {
      content: false,
    }
  },
  methods: {
    showContent(isVisible) {
      if(this.content) {
        isVisible = true;
      }
      this.content = isVisible;
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/seo/amaretis';

</style>
