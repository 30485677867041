<template>
  <div class="container slider">
    <ul class="slider__container">
      <ul class="slider__parent" :class="[{'slider__parent--first' : active === 'first'}, {'slider__parent--second' : active === 'second'}, {'slider__parent--third' : active === 'third'}, {'slider__parent--fourth' : active === 'fourth'}]">
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-studentenwerk-goettingen.de_-300x300.jpg" alt="Logo vom Studentenwerk Göttingen" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-stadt-ludwigsburg-300x300.jpg" alt="Logo von der Stadt Ludwigsburg" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-stadt-goettingen-300x300.jpg" alt="Logo Stadt Göttingen" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-procity-300x300.jpg" alt="Logo von PROCITY Göttingen" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-ihr-einkaufsbahnhof_frankfurter-hauptbahnhof-300x300.jpg" alt="Logo vom Einkaufsbahnhof Göttingen" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-handwerkskammer-Halle-saale-300x300.jpg" alt="Logo der Göttinger Handwerkskammer" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-goettinger-entsorgungsbetriebe-geb-300x300.jpg" alt="Logo der Göttinger Entsorgungsbetriebe" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-diakonie-christophorus-300x300.jpg" alt="Logo Diakonie Christophorus" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-tourismus-und-Congress-gmbh-frankfurt-am-main-300x300.jpg" alt="Logo Tourismus und Congress GmbH Frankfurt" class="slider__parent--item"></li>
        <li><img src="@/assets/images/amaretis-werbeagentur-goettingen-logo-thalia-buecher-gmbh-300x300.jpg" alt="Logo Thalia.de" class="slider__parent--item"></li>
      </ul>
    </ul>
    <ul class="slider__controls">
      <li class="slider__controls--button" id="first" :class="{'slider__controls--button-active' : active === 'first'}" @click="changeSlide"></li>
      <li class="slider__controls--button" id="second" :class="{'slider__controls--button-active' : active === 'second'}" @click="changeSlide"></li>
      <li class="slider__controls--button" id="third" :class="{'slider__controls--button-active' : active === 'third'}" @click="changeSlide"></li>
      <li class="slider__controls--button" id="fourth" :class="{'slider__controls--button-active' : active === 'fourth'}" @click="changeSlide"></li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Slider',
  props: {
    
  },
  data () {
    return {
      content: false,
      active: 'first'
    }
  },
  methods: {
    showContent(isVisible) {
      if(this.content) {
        isVisible = true;
      }
      this.content = isVisible;
    },
    changeSlide(e) {
      let choosenSlide = e.target.id;
      this.active = choosenSlide;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/seo/slider';

</style>
