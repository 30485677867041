<template>
  <div class="container-fluid banner">
    <div class="banner__textcontainer">
      <h3 class="banner__heading">Sie möchten, dass Ihre Website von den Usern besser gefunden wird? Dann starten Sie jetzt mit professioneller SEO von AMARETIS!</h3>
      <a href="https://www.amaretis.de/kontakt" target="_blank" class="banner__button">Kostenlose Erstberatung anfordern</a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Banner',
  props: {
    
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/seo/banner';

</style>
