<template>
  <div class="container-fluid intro" id="top">
    <div class="intro__container" v-observe-visibility="showContent">
      <div class="intro__textContainer hidebefore" :class="{'slideToTop' : content}">
        <p class="intro__text">Suchmaschinenoptimierung (SEO)</p>
        <h1 class="intro__heading">Individuelle SEO-Lösungen für den Raum Kassel:<br/> Mit SEO von AMARETIS werden auch Sie schneller gefunden!</h1>
      </div>
    </div>
  </div>
  
</template>

<script>
export default {
  name: 'Intro',
  props: {
    
  },
  data () {
    return {
      content: false,
    }
  },
  methods: {
    showContent(isVisible) {
      if(this.content) {
        isVisible = true;
      }
      this.content = isVisible;
    } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/base/intro';

</style>
