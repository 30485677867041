<template>
  <div class="container textcontent" id="seo">
    <div class="row">
      <div class="col-md-6">
        <h2>Was ist Suchmaschinen&shy;optimierung (SEO)?</h2>
        <p>Die Abkürzung <strong>SEO</strong> steht für den englischen Begriff <strong>Search Engine Optimization</strong> und bedeutet auf Deutsch <strong>Suchmaschinenoptimierung</strong>, welcher ein wesentlicher Bestandteil des Online-Marketings ist. Darunter versteht man alle Maßnahmen, die dazu beitragen, dass eine Website möglichst weit oben in den organischen (unbezahlten) Suchergebnissen erscheint. Dazu zählen insbesondere eine detaillierte <strong>Keyword-Recherche</strong> und eine <strong>Onpage-</strong> sowie <strong>Offpage-Optimierung</strong>.</p>
      </div>
      <div class="col-md-6">
        <h2>Warum ist SEO so wichtig für Sie?</h2>
        <p>Wer eine Website betreibt, möchte natürlich von den Usern gefunden werden. Nach Eingabe einer Suchanfrage klicken diese meist auf eines der ersten Suchergebnisse. Problem daran ist: Alle Ergebnisse, die unterhalb der ersten 5-10 Rankingplätze stehen, bekommen fast keine Klicks mehr. Damit Sie weiterhin Traffic auf Ihrer Seite erzielen, muss Ihre Website so weit oben stehen, wie möglich.</p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TextContent',
  props: {
    
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/seo/textContent';

</style>
