<template>
  <footer class="container-fluid footer">
    <div class="container">
      <div class="row">
        <div class="col-md-4 col-12">
          <div class="footer__container">
            <h3 class="footer__container--heading">Amaretis</h3>
            <ul class="footer__container--list">
              <li class="footer__container--item">Agentur für Kommunikation GmbH</li>
              <li class="footer__container--item">Düstere Str. 21 <br/>37073 Göttingen</li>
              <li class="footer__container--item">
                <a href="https://www.amaretis.de" target="_blank" class="footer__container--link">www.amaretis.de</a><br/>
                <a href="mailto:info@amaretis.de" class="footer__container--link">info@amaretis.de</a><br/>
                <a href="tel:+495519003640" class="footer__container--link">+49 (0) 551.900 364 0</a><br/>
                +49 (0) 551.900 364 26
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md 4 col-12">
          <div class="footer__container">
            <h3 class="footer__container--heading">Service</h3>
            <ul class="footer__container--list">
              <li class="footer__container--item">
                <a href="https://www.amaretis.de/kontakt" target="_blank" class="footer__container--link">Kontakt</a>
              </li>
              <li class="footer__container--item">
                <a href="https://www.amaretis.de/jobs" target="_blank" class="footer__container--link">Jobs</a>
              </li>
              <li class="footer__container--item">
                <a href="https://www.amaretis.de/datenschutz" target="_blank" class="footer__container--link">Datenschutz</a>
              </li>
              <li class="footer__container--item">
                <a href="https://www.amaretis.de/agb" target="_blank" class="footer__container--link">AGB</a>
              </li>
              <li class="footer__container--item">
                <a href="https://www.amaretis.de/impressum" target="_blank" class="footer__container--link">Impressum</a>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-4 col-12">
          <div class="footer__container">
            <h3 class="footer__container--heading">Menü</h3>
            <ul class="footer__container--list">
              <li class="footer__container--item">
                <a href="#seo" v-smooth-scroll="{duration: 1000}" class="footer__container--link">Suchmaschinenoptimierung (SEO)</a>
              </li>
              <li class="footer__container--item">
                <a href="#services" v-smooth-scroll="{duration: 1000}" class="footer__container--link">Unsere Leistungen</a>
              </li>
              <li class="footer__container--item">
                <a href="#contact" v-smooth-scroll="{duration: 1000}" class="footer__container--link">Kontakt</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid footer__copyright">
      <div class="container">
        <p class="footer__copyright--text">
          © Copyright - <span>Amaretis Agentur für Kommunikation GmbH</span>
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
  props: {
    
  },
  data () {
    return {
      
    }
  },
  mounted() {
    
  },
  methods: {
  
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/base/footer';

</style>
