<template>
  <div class="container iconboxes" v-observe-visibility="showContent">
    <div class="row hidebefore" :class="{'slideToTop' : content}">
      <div class="col-md-4">
        <div class="iconboxes__container">
          <h3 class="iconboxes__heading">Google Ranking verbessern</h3>
          <p class="iconboxes__paragraph">Mit unseren durchgreifenden Methoden verbessern Sie Ihre Platzierung dauerhaft. Unsere Erfahrung & Kreativität bringen wir in allen SEO Bereichen mit ein.</p>
          <div class="iconboxes__icon"><i class="fas fa-thumbs-up"></i></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="iconboxes__container">
          <h3 class="iconboxes__heading">SEO Visibility erhöhen</h3>
          <p class="iconboxes__paragraph">Mehr Sichtbarkeit bedeutet mehr Traffic. Sorgen Sie dafür, dass Ihre Kunden Sie sehen. Erweitern Sie mit neuen Zielgruppen Ihre Reichweite.</p>
          <div class="iconboxes__icon"><i class="fas fa-thumbs-up"></i></div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="iconboxes__container">
          <h3 class="iconboxes__heading">Webseiten Traffic steigern</h3>
          <p class="iconboxes__paragraph">Wir arbeiten gezielt daran, den Traffic auf Ihrer Website zu steigern – und das im organischen Teil der Suchmaschine, ganz ohne Klickkosten.</p>
          <div class="iconboxes__icon"><i class="fas fa-thumbs-up"></i></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'IconBoxes',
  props: {
    
  },
  data () {
    return {
      content: false,
    }
  },
  methods: {
    showContent(isVisible) {
      if(this.content) {
        isVisible = true;
      }
      this.content = isVisible;
    } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/seo/iconBoxes';

</style>
