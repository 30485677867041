<template>
  <div class="container-fluid topbar">
    <div class="container topbar__container">
      <ul class="topbar__menu">
        <li class="topbar__item">+49 (0) 551.900 364 0 &bull; <a href="mailto:info@amaretis.de">INFO@AMARETIS.DE</a></li>
        <li class="topbar__item topbar__item-twitter"><a href="https://twitter.com/amaretis_gmbh" target="_blank" title="Amaretis Twitter"><i class="fab fa-twitter"></i></a></li>
        <li class="topbar__item"><a href="https://www.facebook.com/AgenturAmaretis/" target="_blank" title="Amaretis Facebook"><i class="fab fa-facebook-f"></i></a></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Topbar',
  props: {
    
  },
  data () {
    return {
      
    }
  },
  methods: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/base/topbar';

</style>
