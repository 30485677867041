<template>
  <div class="container enumeration" id="services" v-observe-visibility="showContent">
    <h2 class="heading-primary text-center">Unsere Leistungen im Bereich SEO</h2>
    <div class="row enumeration__parent hidebefore" :class="{'slideToTop' : content}">
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> SEO- Komplettbetreuung</h3>
          <p class="enumeration__container--paragraph">Wir bleiben an Ihrer Seite. D.h. wir richten Ihnen nicht etwas ein und sind weg, sondern wir betreuen die Seite durchgehend, checken das Ranking regelmäßig, passen die Seite neuen Anforderungen, Richtlinien und Vorgaben an.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Webseiten-Analyse</h3>
          <p class="enumeration__container--paragraph">Wir schauen uns Ihre aktuelle Seite an, analysieren Stärken und Schwächen. Weiter überlegen wir, welche Inhalte kommuniziert werden sollen und welche technischen Möglichkeiten zur Verfügung stehen.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> SEO-Analyse</h3>
          <p class="enumeration__container--paragraph">Hier geht es genauer um die technischen Möglichkeiten zur Suchmaschinenoptimierung: Welche Keywords, welche Links, welche Bilder … bringen Hits bei google & Co.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> SEO-Beratung</h3>
          <p class="enumeration__container--paragraph">Nach der maßgeschneiderten Webseitenanalyse ist und bleibt auch die Beratung maßgeschneidert: inhaltlich und terminlich. Mit Sitz in der Nähe von Kassel können wir Sie persönlich beraten oder natürlich auch telefonisch.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> SEO-Strategie</h3>
          <p class="enumeration__container--paragraph">Es gilt eine genaue Strategie zu erarbeiten: Bis wann möchten Sie welche Hits erreichen und wie ist das unter den Gegebenheiten möglich. Wenn man die Ziele klar definiert, kann man sie später leicht überprüfen.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Wettbewerbsanalyse</h3>
          <p class="enumeration__container--paragraph">Was machen Sie anders, und was machen Sie besser als Ihre Wettbewerber? Wie kann das auf Ihrer Website kommuniziert werden?</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Technisches SEO</h3>
          <p class="enumeration__container--paragraph">Der schönste Text, das beste Layout nützt nichts, wenn die Technik nicht stimmt. Unser IT-Support korrigiert veraltete Seiten und Programmierfehler und reduziert die 404-Fehlermeldungen. Gewonnene Kunden, die auf Ihre Seite gefunden haben, sollen auch dort bleiben.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Onpage-Optimierung</h3>
          <p class="enumeration__container--paragraph">OnPage-Optimierung beschreibt all das, was der Websitebetreiber selbst tun kann, um Suchmaschinen-Algorithmen optimal zu nutzen: Dabei geht es um Text, Struktur und Aufbau der Site.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Offpage-Optimierung</h3>
          <p class="enumeration__container--paragraph">Die OffPage-Optimierung beschreibt Faktoren abseits der eigentlichen Website, die das Ranking positiv beeinflussen: eine große Rolle spielen dabei die Backlinks zu anderen Seiten.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Keywort-Optimierung</h3>
          <p class="enumeration__container--paragraph">Welche Schlagworte (Keywords) benutzen die User, um nach meinem Produkt zu suchen? Eine bloße Aneinanderreihung von Keywords wie einst reicht hierbei nicht mehr. Wir schreiben einen Text für Sie, der Inhalt und Algorithmen gleichermaßen gerecht wird.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Linkbuilding & Internationales Linkbuilding</h3>
          <p class="enumeration__container--paragraph">Backlinks, Links zu anderen Websites gleichen Themas, sind unerlässlich für ein gutes Ranking. (s. Offpage-Optimierung)</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Kontinuierliches Tracking</h3>
          <p class="enumeration__container--paragraph">„We do not sell - and run.” Wir kontrollieren den Erfolg, passen die Seite an neue Gegebenheiten an, korrigieren ggf. Durch die klar formulierten Ziele kann dies auch gewährleistet werden.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Google Penalty-/ Filter-Entfernung</h3>
          <p class="enumeration__container--paragraph">Man muss Filterregeln und No-gos kennen. Google Strafen gilt es zu verhindern. Ist es passiert, helfen wir Ihnen zurück zu den vordersten Plätzen.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Google My Business-Optimierung</h3>
          <p class="enumeration__container--paragraph">Google My Business bietet optimale Möglichkeiten Ihre Zielgruppen mit bestimmten Suchanfragen abzuholen.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Amazon SEO</h3>
          <p class="enumeration__container--paragraph">„Nutzern, denen X gefiel, gefiel auch Y.“ Wer kennt den Satz nicht? Wir helfen Ihnen die Amazon-Algorithmen und die Amazon-Welt für Ihr Unternehmen zu nutzen.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> SEO-Texte und Content Optimierung</h3>
          <p class="enumeration__container--paragraph">Ein Herzstück Ihrer Seite ist der Text. Wir texten so, wie es für Ihr Produkt am besten ist, wie es Ihnen gefällt und so wie Sie die besten Ranks bekommen.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> Mobile SEO</h3>
          <p class="enumeration__container--paragraph">Handy, Tablet und Co: Bis zu vierzig Prozent der Suchanfragen werden derzeit von mobilen Endgeräten aus getätigt. Die Site muss auch auf dem Handy gut gerankt und optimal lesbar sein.</p>
        </div>
      </div>
      <div class="col-lg-4 col-md-6">
        <div class="enumeration__container">
          <h3 class="enumeration__container--heading"><i class="fas fa-check"></i> SEO-Reports</h3>
          <p class="enumeration__container--paragraph">Sie bekommen regelmäßig transparente Reportings. Und dank der klaren Zielsetzung sind die auch aussagekräftig.</p>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 text-center"><a href="https://www.amaretis.de/kontakt/" target="_blank" class="enumeration__button">Jetzt kostenlose Beratung anfordern</a></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Enumeration',
  props: {
    
  },
  data () {
    return {
      content: false,
    }
  },
  methods: {
    showContent(isVisible) {
      if(this.content) {
        isVisible = true;
      }
      this.content = isVisible;
    } 
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/seo/enumeration';

</style>
