<template>
  
  <nav class="navbar">
    <div class="container-fluid navbar__background">
      <div class="container navbar__parent">
        <a href="#top" v-smooth-scroll="{duration: 1000}" class="navbar__brand">
          <img src="@/assets/images/logo_amaretis_marketing-300x106.png" class="navbar__image" :class="{'navbar__image-shrink' : scrolled}" alt="Logo von Amaretis">
        </a>
        <ul class="navbar__menu" :class="{'navbar__menu--mobile' : mobileNav}">
          <li class="navbar__item" @click="showActiveNav('seo'); mobileNav = !mobileNav"><a href="#seo" v-smooth-scroll="{duration: 1000}" class="navbar__link" :class="{'navbar__link-active' : seo}">Suchmaschinen&shy;optimierung (SEO)</a></li>
          <hr>
          <li class="navbar__item" @click="showActiveNav('leistungen'); mobileNav = !mobileNav""><a href="#services" v-smooth-scroll="{duration: 1000}" class="navbar__link" :class="{'navbar__link-active' : leistungen}">Unsere Leistungen</a></li>
          <hr>
          <li class="navbar__item" @click="showActiveNav('kontakt'); mobileNav = !mobileNav""><a href="#contact" v-smooth-scroll="{duration: 1000}" class="navbar__link" :class="{'navbar__link-active' : kontakt}">Kontakt</a></li>
        </ul>
        <div class="navbar__toggle" :class="{animateBar: mobileNav}" @click="mobileNav = !mobileNav">
          <div class="navbar__toggle--bar navbar__toggle--bar-1"></div>
          <div class="navbar__toggle--bar navbar__toggle--bar-2"></div>
          <div class="navbar__toggle--bar navbar__toggle--bar-3"></div>
        </div>
      </div>
    </div>
  </nav>


</template>

<script>
export default {
  name: 'Navbar',
  props: {
    
  },
  data () {
    return {
      seo: true,
      leistungen: false,
      kontakt: false,
      scrolled: false,
      mobileNav: false,
    }
  },
  mounted() {
    this.resizeNavbar();
  },
  methods: {
    showActiveNav(nav) {
      if (nav === 'seo') {
        this.seo = true;
        this.leistungen = false;
        this.kontakt = false;
      } else if (nav === 'leistungen') {
        this.seo = false;
        this.leistungen = true;
        this.kontakt = false;
      } else {
        this.seo = false;
        this.leistungen = false;
        this.kontakt = true;
      }
    },
    resizeNavbar() {
      window.addEventListener('scroll', () => {
        if(document.body.scrollTop > 50 || document.documentElement.scrollTop > 50) {
          this.scrolled = true;
        } else {
          this.scrolled = false;
        }
      });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/components/base/navbar';

</style>
